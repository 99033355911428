.table {
  .cellWrapper {
    display: flex;
    align-items: center;

    .image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
  }

  .status {
    padding: 5px;
    border-radius: 5px;

    &.completed {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }
    &.assigned {
      color: goldenrod;
      background-color: rgba(189, 189, 3, 0.103);
    }
    &.cancelled {
      color: white;
      background-color: red;
    }
  }
}
.checkbox-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #000; /* Customize the appearance of the empty box */
}

.checkbox.Mui-checked .checkbox-icon {
  background-color: #c8e6c9; /* Customize the background color of the checked box */
  border-color: #c8e6c9; /* Match the background color for consistency */
}
